


















import {userModule} from '@/store'
import {RawLocation} from 'vue-router'
import truncate from 'lodash/truncate'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {Chart, DoughnutController, ArcElement} from 'chart.js'

import { AuthUserResource } from '@/models/users/AuthUserResource'
import { ProcessResource } from '@/models/process/ProcessResource'

Chart.register(DoughnutController)
Chart.register(ArcElement)

@Component
export default class ProjectStepTile extends Vue {

  @Prop() private data!: ProcessResource

  private isHovering: boolean = false
  private chart: Chart | undefined = undefined

  @Watch('data')
  private onDataChange(process: ProcessResource): void {
    if (this.chart) {
      this.chart.destroy()
    }
    this.renderChart()
  }

  private get user(): AuthUserResource | undefined {
    return userModule.user
  }

  private get canCreate(): boolean {
    return this.data.order > 0 && this.data.canPerformAction('can_create_proposal')
  }

  private get processName(): string {
    return this.data ? this.data.name.replace(/-/g, ' ') : ''
  }

  private get buttonRoute(): RawLocation {
    return {
      name: `projects-detail-process`,
      params: {project_id: this.data.project_id.toString(), process_id: this.data.id.toString()}
    }
  }

  private tooltipContent() {
    let htmlString = `<div class="status-tooltip">`

    for (const status of this.data.statuses) {
      htmlString += `<div class="status-tooltip-item">`
      htmlString += `  <span class="status-dot" style="background-color:${status.color};"></span>`
      htmlString += `  <h4 title="${status.label}">${ truncate(status.label, { length: 20 }) }</h4>`
      htmlString += `  <h5>${status.count}</h5>`
      htmlString += `</div>`
    }

    htmlString += `</div>`
    return htmlString
  }

  private mounted() {
    this.renderChart()
  }

  private renderChart(): void {
    const canvas = this.$refs.chart as HTMLCanvasElement

    const hasProposals = (this.data.proposals_count ?? 0) > 0
    let data: number[] = []
    let labels: string[] = []
    let backgroundColor: string[] = []

    // If no proposals then add one only datapoint just to have a empty donut chart
    if (!hasProposals) {
      data.push(1)
      backgroundColor.push('#DCDCDC')
    } else {
      data = this.data.statuses.map((status) => status.count ?? 0)
      labels = this.data.statuses.map((status) => `${status.label}`)
      backgroundColor = this.data.statuses.map((status) => status.color)
    }


    if (!canvas) return
    this.chart = new Chart(canvas, {
      type: 'doughnut',
      options: {
        cutout: '85%',
        maintainAspectRatio: false,
      },
      data: {
        labels,
        datasets: [{
          data,
          backgroundColor,
          borderWidth: 0
        }]
      }
    })
  }
}
